import * as yup from "yup";

//const phoneRegExp = /\d{10}/;
const passworRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const nameRegExp = /^[ a-zA-Z0-9\-’]+$/;
const colorRegExp = /^[a-zA-z]/;
// eslint-disable-next-line
const zipRegExp = /^(\+\d{1,3}[- ]?)?\d{5}$/;
// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const weburlExp =
  /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
const wholeNumberRegex = /^[0-9]+$/;

//Validation for SignUp Page fields

const signUpValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    firstname: yup
      .string()
      .trim()
      .required(translation("fname_req"))
      .matches(nameRegExp, translation("fname_valid"))
      .max(25, translation("max_len_25")),
    lastname: yup
      .string()
      .trim()
      .required(translation("lname_req"))
      .matches(nameRegExp, translation("lname_valid"))
      .max(25, translation("max_len_25")),
    address: yup
      .string()
      .trim()
      .required(translation("address_req"))
      .max(150, translation("max_len_150")),
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .email(translation("email_valid")),
    password: yup
      .string()
      .required(translation("pass_req"))
      .matches(passworRegExp, translation("pass_reg_ms"))
      .max(25, translation("max_len_25")),
      //phone_number: yup.string().trim().required(translation('required_phone')),

    acceptTerms: yup
      .boolean()
      .required("The terms and conditions must be accepted.")
      .oneOf([true], "The terms and conditions must be accepted."),

    adult: yup
      .boolean()
      .required(".You must be 18 years old or above")
      .oneOf([true], "You must be 18 years old or above"),
      //id_number: yup.string().required("Id number is required"), // ( National ID, CNIC, SNIC ) required if other countries
     // routing_number: yup.string().required("Routing number is required"), // ( Routing number OR Swift/BIC code ) required if other countries
     // account_number: yup.string().required("Account number is required"), // ( IBAN Number ) required if other countries
     // id_proof: yup.string().required("Please attach your picture of id"),
  });

//Validation at the time of user login
const loginValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .email(translation("email_valid")),
    password: yup
      .string()
      .required(translation("pass_req"))
      .max(25, translation("max_len_25")),
  });

//Validation for forgot password fields
const forgotPasswordValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .email(translation("email_valid")),
  });

//Validation for reset password fields
const resetPassWordValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .email(translation("email_valid")),
    password: yup
      .string()
      .required("Password is required")
      .min(4, "Password length should be at least 4 characters"),
    confirm_password: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password")], "Passwords must and should match"),
  });
const profileValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    breed: yup.string().trim().required(translation("breed_req")),

    // picture: yup
    //     .string()
    //     .trim()
    //     .required("profile image is required"),
    // cover_image: yup
    //     .string()
    //     .trim()
    //     .required("cover image is required"),

    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .email(translation("email_valid")),
    // date: yup.string().required(translation("date_req")),
    type: yup.string().trim().required(translation("type_req")),
    // location: yup
    //     .string()
    //     .trim()
    //     .required("location is required"),
    // phone_number: yup
    //   .string()
    //   .required("Phone number  is required"),
     // .matches(phoneRegExp, "Phone number is not valid"),

    bio: yup.string().required(translation("bio_req")),
    gender: yup.string().required(translation("select_gen")),
  });
const adoptionValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(translation("name_req"))
      .matches(nameRegExp, translation("enter_name"))
      .max(25, translation("max_len_25")),
    description: yup.string().required(translation("bio_req")),
    type: yup.string().trim().required(translation("type_req")),
    breed: yup.string().trim().required(translation("breed_req")),
    color: yup
      .string()
      .trim()
      .matches(colorRegExp, translation("color_err_ms"))
      .required(translation("color_req")),
    age: yup
      .number()
      .typeError(translation("err_age"))
      .positive()
      .nullable(false)
      .required(translation("age_req")),
    location: yup.string().trim().required(translation("location_req")),
  });
const LostFoundValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    name: yup.string().when("status", {
      is: (status) => status === "Lost" || status === "",
      then: yup
        .string()
        .trim()
        .required(translation("name_req"))
        .matches(nameRegExp, translation("enter_name"))
        .max(25, translation("max_len_25")),
    }),
    description: yup.string().required(translation("bio_req")),
    type: yup.string().trim().required(translation("type_req")),
    status: yup.string().trim().required(translation("status_req")),
    breed: yup.string().when("status", {
      is: (status) => status === "Lost",
      then: yup.string().trim().required(translation("breed_req")),
    }),
    color: yup
      .string()
      .trim()
      .matches(colorRegExp, translation("color_err_ms"))
      .required(translation("color_req")),

    age: yup
      .number()

      .when("status", {
        is: (status) => status === "Lost",
        then: yup
          .number()
          .required()
          .typeError(translation("err_age"))
          .positive()
          .nullable(false),
      }),
    location: yup.string().trim().required(translation("location_req")),
  });

const AdListSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    company_name: yup.string().trim().required(translation("name_req")),
    company_website_url: yup
      .string()
      .trim()
      .required("Url is Required")
      .matches(weburlExp, "Please Enter a valid Url"),
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .email(translation("email_valid")),
    location: yup.string().trim().required(translation("location_req")),
    region: yup.string().trim().required("Region is Required"),

    reason: yup.string().trim().required(translation("ad_reason_validation")),

    //start_date: yup.string().required(translation("date_req")),
    phone_number: yup
      .string()
      .required("Phone required")
      .matches(phoneRegExp, "Phone number is not valid"),
    // picture: yup
    //     .string()
    //     .trim()
    //     .required("Image is required"),
  });

const settingBudgetScheme = yup.object().shape({
  packageBudget: yup.string().trim().required("Budget is required"),
});

const verifyEmailValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .email(translation("email_valid")),
    otp: yup.string().trim().required(translation("otp_req")),
  });

const createSellerProfileValidationSchema = (
  translation: (key: string) => string
) =>
  yup.object().shape({
    firstname: yup
      .string()
      .trim()
      .required(translation("fname_req"))
      .matches(nameRegExp, translation("fname_valid"))
      .max(25, translation("max_len_25")),
    lastname: yup
      .string()
      .trim()
      .required(translation("lname_req"))
      .matches(nameRegExp, translation("lname_valid"))
      .max(25, translation("max_len_25")),
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .email(translation("email_valid")),
    location: yup.string().trim().required(translation("location_req")),

    phone_number: yup
      .number()
      .typeError(translation("err_phone1"))
      .positive(translation("err_phone2"))
      .integer(translation("err_phone3"))
      .required(translation("phone_req")),
    description: yup.string().trim().required(translation("desc_req")),
    tax_id: yup.string().trim(), //.required(translation("tax_id_req")),
  });

  const editSellerProfileValidationSchema = (
    translation: (key: string) => string
  ) =>
    yup.object().shape({
      firstname: yup
        .string()
        .trim()
        .required(translation("fname_req"))
        .matches(nameRegExp, translation("fname_valid"))
        .max(25, translation("max_len_25")),
      lastname: yup
        .string()
        .trim()
        .required(translation("lname_req"))
        .matches(nameRegExp, translation("lname_valid"))
        .max(25, translation("max_len_25")),
      email: yup
        .string()
        .trim()
        .required(translation("email_req"))
        .email(translation("email_valid")),
     
      phone_number: yup
        .number()
        .typeError(translation("err_phone1"))
        .positive(translation("err_phone2"))
        .integer(translation("err_phone3"))
        .required(translation("phone_req")),
      
    });
const createProductValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(translation("Pname_req"))
      .matches(nameRegExp, translation("pname_valid"))
      .max(50, translation("max_len_50")),
    category: yup.string().trim().required(translation("category_req")),
    description: yup.string().trim().required(translation("pdesc_req")),
    price: yup
      .number()
      .typeError(translation("err_price"))
      .positive(translation("err_price1"))
      .required(translation("price_req")),
    quantity: yup
      .string()
      .required("Quantity is required")
      .matches(
        wholeNumberRegex,
        "Invalid number, please enter whole number, decimal and negative numbers are not allow"
      ),
    availability: yup.string().required(translation("avail_req")),
  });

const verifyEmailAddressValidationSchema = (
  translation: (key: string) => string
) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .email(translation("email_valid")),
  });

const CardValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(translation("fname_req"))
      .matches(nameRegExp, translation("fname_valid"))
      .max(25, translation("max_len_25")),
    email: yup
      .string()
      .trim()
      .required(translation("email_req"))
      .email(translation("email_valid")),
    phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    address: yup.object().shape({
      postal_code: yup
        .string()
        .required("zip code is required")
        .min(4, translation("zip code is not valid"))
        .max(7, translation("zip code is not valid")),
      //.matches(zipRegExp, "zip code is not valid"),
      state: yup.string().required("State is Required"),
      city: yup.string().required("city is Required"),
    }),
  });

const shippingAddressPageField = (isDifferentAddress: boolean) =>
  yup.object().shape({
    firstname: yup
      .string()
      .trim()
      .required("First Name is required")
      .matches(nameRegExp, "Enter Valid First name")
      .max(25, "Must be less than or equal 25 digits"),
    lastname: yup
      .string()
      .trim()
      .required("Last Name is required")
      .matches(nameRegExp, "Enter valid Last name")
      .max(25, "Must be less than or equal 25 digits"),
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .email("Enter Valid Email"),
    phone_number: yup
      .string()
      .required("Phone Number is required")
      .matches(phoneRegExp, "Phone number is not valid"),
    address: isDifferentAddress
      ? yup.string().trim()
      : yup
          .string()
          .trim()
          .required("Address is required"),
          //.min(25, "Must be greater than or equal 25 digits"),
    // .max(150, "Must be less than or equal 150 digits"),
    city: yup.string().trim().required("City is Required"),
    // country: yup.string().required("Country is Required"),
    // post_code: yup.string()
    //   .required("zip code is required")
    //   .min(4, "Post code is not valid")
    //   .max(7, "Post code is not valid"),
    zip: yup
      .string()
      .required("Zip code is required")
      .min(4, "Zip code is not valid")
      .max(7, "Zip code is not valid"),
    diff_address: isDifferentAddress
      ? yup
          .string()
          .trim()
          .required("Different address is required")
          // .min(25, "Must be greater than or equal 25 digits")
      : yup.string().trim(),
    // .max(150, "Must be less than or equal 150 digits"),
  });

export {
  signUpValidationSchema,
  loginValidationSchema,
  forgotPasswordValidationSchema,
  adoptionValidationSchema,
  resetPassWordValidationSchema,
  profileValidationSchema,
  LostFoundValidationSchema,
  verifyEmailValidationSchema,
  verifyEmailAddressValidationSchema,
  createSellerProfileValidationSchema,
  createProductValidationSchema,
  AdListSchema,
  CardValidationSchema,
  shippingAddressPageField,
  settingBudgetScheme,
  editSellerProfileValidationSchema
};
